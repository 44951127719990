const transitionDelay = 50;
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const savedPosition = getSavedScrollPosition(location);

  window.setTimeout(
    () => window.scrollTo(...(savedPosition || [0, 0])),
    transitionDelay
  );

  return false;
};
